:root {
    --white: #fff;
    --black: #000;
    --white2: #F4F4F7;
    --purple: #8A5DA4;
    --facebook: #196EDA;
    --intagram: #B333A7;
    --linkedin: #1666C2;
    --youtube: #E32307;
    --black2: #999;
    --black3: #555;
    --black4: #47484C;
    --yellow: #EAAE0D;
    --blue: #41A2F7;
    --serviceIconBg: #042762;
    --whatsapp: #25d366;
    --gray: #ddd;
    --gray2: #eee;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 10px;
}

body {
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
}

ul li {
    list-style: none;
}

a {
    text-decoration: none;
}
button{
    cursor: pointer;
}
.home_header {
    width: 100%;
    height: 100vh;
    /* overflow: hidden; */
}

.container {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

textarea {
    font-family: 'Mulish', sans-serif;
}

.header_head_container_bg {
    background-color: var(--white2);
    height: 4vh;
}

.header_head_container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
}

.header_first_list {
    display: flex;
    gap: 40px;
    align-items: center;
    /* background-color: red; */
}

.header_first_list li {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header_first_list li a {
    color: var(--black);
    font-size: 1.5rem;
    transition: all 0.3s ease-in-out;
}

.header_first_list li a:hover {
    color: var(--purple);
}

.header_main_container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 96vh;
    justify-content: space-between;
}

.header_slider {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.header_second_list {
    display: flex;
    gap: 10px;
    align-items: center;
}

.header_second_list li a {
    color: var(--black3);
    font-size: 1.8rem;
    transition: all 0.5s ease-in-out;
}

.header_second_list li:nth-child(2) a:hover,
.other_contact_content .header_second_list li:nth-child(1) a:hover {
    color: var(--facebook);
}

.header_second_list li:nth-child(3) a:hover,
.other_contact_content .header_second_list li:nth-child(2) a:hover {
    color: var(--intagram);
}

.header_second_list li:nth-child(4) a:hover,
.other_contact_content .header_second_list li:nth-child(3) a:hover {
    color: var(--linkedin);
}

.header_second_list li:nth-child(5) a:hover,
.other_contact_content .header_second_list li:nth-child(4) a:hover {
    color: var(--youtube);
}

.logo {
    width: 140px;
}

.header_second_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 99999;
    
}

.header_second_cards_container {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    margin-bottom: 40px;
    z-index: 99;
}

.main_second_cards_container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    max-width: 500px;
    

}

.main_first_cards {
    display: none;
    padding-top: 50px;
    /* padding-bottom: 30px; */
}

.header_second_card {
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
    padding: 30px 0px;
    min-width: 370px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
}

.header_second_card svg {
    width: 40px;
    stroke-width: 10px;
}

.header_second_card p {
    color: var(--white);
}

.header_second_card:hover {
    transform: translateY(-10px);
}

.header_second_card:nth-child(1):hover {
    background-color: var(--blue);
}





.header_second_card:nth-child(2):hover {
    background-color: var(--yellow);

}

.header_second_card:nth-child(3):hover {
    background-color: var(--serviceIconBg);
}

.header_second_card:nth-child(4):hover {
    background-color: var(--purple);
}

.header_second_container_bg {
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 9999;
    /* background-color: red !important; */
    background-color: var(--purple);
}

/* .header_second_container_bg.bg {
    background-color: var(--purple);
    position: fixed;
    left: 0;
    top: 4vh;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    animation: navbarAnime 0.5s ease-in-out;
} */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

@keyframes navbarAnime {
    0% {
        top: -100vh;
    }

    100% {
        top: 0;
    }
}

.header_second_card_icon {
    font-size: 3rem;
}

.header_second_card img {
    width: 40px;
}

.header_second_card div {
    background-color: var(--white);
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.header_second_card p {
    text-align: center;
    margin-top: 10px;
    font-size: 1.4rem;
}

.header_second_card:hover div {
    position: relative;
    animation: pulse 2s ease-out infinite;

}

.header_second_card:hover div::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 50%;
    position: absolute;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0px var(--purple), 0 0 0 0px var(--purple);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        box-shadow: 0 0 0 12px rgba(0, 210, 255, 0), 0 0 0 25px rgba(0, 210, 255, 0);
    }
}

.header_nav_bg {
    background-color: var(--purple);
    margin-top: 15px;
    position: relative;
}

.nav_list {
    display: flex;
    gap: 16px;

}

.nav_list li a {
    color: var(--white);
    text-transform: uppercase;
    font-size: 1.5rem;
}



.nav_list li {
    padding: 15px 0px;
    transition: all 0.5s ease-in-out;
    height: 100%;
    display: block;


}

.bars_icon {
    margin-right: 15px;
    color: var(--white);
    font-size: 2.2rem;
    display: none;
}

.close_parent {
    display: none;
    /* background-color: red; */
    text-align: end;
    padding: 20px 20px 5px 0;
}

.close_icon {
    color: var(--black);
    font-size: 2.5rem;

}

.angle_icon {
    display: none;
}

.nav_inner_list {
    position: relative;

}

.nav_inner_list ul {
    position: absolute;
    background-color: var(--white);
    top: -1000px;
    width: 350px;

}

.nav_inner_list:hover ul {
    top: 40px;


}

.nav_inner_list ul li a {
    font-weight: 400;
}

.nav_inner_list ul li {
    border-bottom: 0.1px solid var(--black2);
    padding: 8px;
}

.nav_inner_list:hover ul li a {
    color: var(--black3);

}

.nav_inner_list ul li a:hover {
    color: var(--purple);
    transition: all 0.5s ease-in-out;
}

.galarey_list ul {
    width: 150px;
}

.header_second_list_search_icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.fixed_search_container {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9999999999999999;
    background-color: rgba(0, 0, 0, 1);
    overflow: hidden !important;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fixed_search {
    min-width: 300px;
    height: 70px;
    /* background-color: red; */
    display: flex;
    align-items: center;
}

.fixed_search .popup_search_icon {
    color: var(--white);
    font-size: 2.5rem;
}

.fixed_search input {
    width: 90%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    font-size: 3rem;
    color: var(--white);
    border-bottom: 0.2px solid var(--gray);

}

.fixed_search_close_icon {
    position: absolute;
    right: 0;
    top: -100px;
    color: var(--white);
    font-size: 4rem;
    cursor: pointer;
}

.fixed_search_close_icon_container {
    position: relative;
    background-color: red;
    width: 300px;
}

.search_icon {
    color: var(--white);
    font-size: 2.4rem;
    cursor: pointer;

}
.fixed_up_to_container{
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: var(--purple);
    z-index: 999999;
    width: 30px;
    height: 30px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.fixed_up_to{
    font-size: 2.4rem;
}
.header_search_container {
    position: absolute;
    background-color: var(--white);
    left: -10000px;
    z-index: 999;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;


}

.header_search_container input {
    width: 95%;
    border: 0.1px solid var(--black2);
    padding: 8px;
    outline: none;
    font-size: 1.6rem;
}

.header_search_container .search_icon {}

.nav_list button {
    background: none;
    border: none;
    outline: none;
}

.nav_list button {
    padding: 15px 10px;
    transition: all 0.5s ease-in-out;


}



.header_search_container button {
    border: none;
    outline: none;
    padding: 6px;
    background-color: var(--purple);

}

nav {
    position: relative;
    display: flex;
    align-items: center;
}

.nav_search_btn:hover .header_search_container {
    left: 0;
}

.nav_search_btn {
    background: none;
    border: none;
    outline: none;
    margin-left: 10px;
    padding-top: 6px;
    padding-bottom: 3px;
}

.header_slider_card {
    /* background-color: lime; */
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    padding: 0 !important;
}

.header_slider_cards_container {
    height: 96vh;
    padding: 0;
    position: relative;
}

.header_slider_bg_shadow {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);

    /* padding-top: 130px; */
}

.header_main_flex_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: flex-start; */
    /* background-color: red; */
    height: 100%;
    /* width: 100% !important; */
    /* justify-content: space-between; */
}

.header_middle_container_content {
    /* background-color: lime; */
}

.header_middle_container_content h1 {
    color: var(--white);
    font-size: 4rem;
    max-width: 700px;
    font-weight: 500;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    font-family: 'Merriweather Sans', sans-serif;
}

.header_middle_container_content button {
    margin-top: 50px;
    font-size: 1.8rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    color: var(--black);
    border: none;
    padding: 8px 25px;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
    transition-delay: 0.35s;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: var(--white);
}

.header_middle_container_content button:before {
    transform: translate(-110%, -110%) translateZ(0);
    content: "";
    display: block;
    background: var(--yellow);
    position: absolute;
    width: 200%;
    height: 500%;
    border-radius: 100%px;
    transition: 0.36s cubic-bezier(0.4, 0, 1, 1);
    color: var(--white);
}

.header_middle_container_content button:hover:before {
    transform: translate(-45%, -34%) translateZ(0);
}

.header_middle_container_content button:hover span {
    color: var(--white);
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
    transition: 0.25s ease-in;
    transition-delay: 0.1s;
}











.swiper-pagination {
    position: absolute;
    left: 50% !important;
    bottom: 0 !important;
    /* bottom: 0%; */
    transform: translateX(-50%);
    height: 60px;
    text-align: center;
    max-width: 1170px;
    /* margin-bottom: 10px !important; */
    /* background-color: red; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: var(--white);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    text-align: left;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: var(--purple);
    border: 2px solid var(--white);
}
.exam_result_select{
    background: var(--purple);
    color: var(--white);
    border: none;
    outline: none;
    padding: 4px 7px;
    font-size: 1.8rem;
    border-radius: 6px;
    appearance: none;

    width: 100%;
    margin-bottom: 20px;
    
}
.section_header {
    text-align: center;
    /* text-transform: uppercase; */
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #8328eb 67%,
            #9503e3 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;

    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    /* display: inline-block; */
    /* font-size: 190px; */
    font-size: 4rem;

}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.section_header_link {
    text-align: center;
    display: block;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
    margin-bottom: 6px;
    font-style: italic;
    color: var(--black);
    transition: all .5s ease-in-out;
    position: relative;
    margin-bottom: 80px;
    font-size: 1.4rem;
    font-family: 'Droid Serif', serif;

}

.section_header_container {
    padding-top: 40px;
}

.section_header_link:hover {
    color: var(--purple);
}

.section_header_link::before {
    position: absolute;
    content: '';
    width: 120px;
    height: 3px;
    background-color: var(--purple);
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
}

.video_container {

    margin-bottom: 100px;
}

.video_card {
    /* min-width: 550px; */
    /* background-color: red; */
    /* width: 277px !important; */
}

.video_card iframe {
    width: 100%;
    height: 315px;
    border: none;
}

.news {
    /* background: url('../images/bg-news.webp');
    padding-top: 60px; */
}

.news_slider_card_container {
    /* background-color: red; */
    min-height: 350px;
    display: flex;
    justify-content: flex-start;
}

.news_slider_card {
    border-radius: 6px;
    transition: 0.5s;
    /* background-color: rgba(255, 255, 255, 0.3); */
    height: 300px;
    /* border: 1px solid #eee; */
    /* z-index: 999 !important; */
    position: relative;
    overflow: hidden;
    /* min-width: 277px; */


}

.news_slider_card:hover {
    /* box-shadow: 0px 0px 9px rgba(69, 69, 69, 0.23); */
    /* min-height: 350px; */
    /* filter: brightness(0.5); */
    background: linear-gradient(180deg,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.3) 10%,
            hsl(0, 0%, 0%) 100%);


}

.news_slider_card img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;

}

.news_slider_card h3 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 800;
    font-family: 'Mulish', sans-serif;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 4px;
    color: var(--white);

}

.news_slider_card .new_slider_card_content_header {
    position: absolute;
    bottom: -7px;
    background: linear-gradient(180deg,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.3) 10%,
            hsl(0, 0%, 0%) 100%);
    padding: 15px;
    width: 100%;
}

.news_slider_card:hover .new_slider_card_content_header {
    left: -30000px;
}

.new_slider_card_content {
    /* background-color: red; */
    position: absolute;
    bottom: -400px;
    background: linear-gradient(180deg,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.3) 10%,
            hsl(0, 0%, 0%) 100%);
    border-radius: 0 0 6px 6px;
    transition: all 0.5s ease-in-out;
    width: 100%;
}

.news_slider_card:hover .new_slider_card_content {
    bottom: 0;
}

.news_slider_card span {
    /* display: block; */
    /* text-align: center; */
    font-style: italic;
    color: #eee;
    font-family: 'PT Serif', serif;
    font-size: 1.4rem;

}

.news_slider_card p {
    color: var(--white);
    text-align: center;
    font-size: 1.5rem;
    margin-top: 10px;
    /* font-weight: 300; */
    opacity: 0.8;
}

.news_slider_card_end {
    display: flex;
    align-items: center;
    border-top: 0.1px solid #eee;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 30px;
    gap: 4px;
    /* background-color: red; */
}

.news_slider_card_end .calendar {
    color: var(--white);
}

.news_swiper_btns {
    max-width: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 100px;
}

.swiper_button {
    font-size: 1.2rem;
    background-color: var(--white);
    /* box-shadow: 0 0 9px rgba(69, 69, 69, 0.23); */
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black2);
    cursor: pointer;
    color: var(--black);
    transition: 0.5s;
}

.swiper_button:hover {
    border: 1px solid var(--purple);
    color: var(--purple);

}

.news_slider_card_img {
    /* height: 160px; */
    /* background-color: red; */
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.6); */

}

.news_slider_card_img img {
    width: 100%;
    object-fit: cover;
}

.news_slider_card_link {
    color: var(--white);
    font-size: 1.6rem;
    background-color: var(--purple);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5px;
    transform: translateY(-500%);
    transition: all 0.3s ease-in-out;
}

.news_slider_card_link_bg_shadow {
    position: absolute;
    left: -10000px;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
}

.news_slider_card:hover .news_slider_card_link_bg_shadow {
    left: 0;
}

.news_slider_card:hover .news_slider_card_link {
    transform: translateY(0%);
}

.services_section {
    background-color: var(--white2);
    padding-bottom: 80px;
    margin-bottom: 40px;
    position: relative;
    padding-top: 160px;
}

.services_header_img {
    background: url('../images/tip1.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 250px;
    /* z-index: 0; */
    position: absolute;
    width: 100%;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
}

.services_header_img img {}

.services_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;
}

.services_card {
    min-width: 277px;
    /* background-color: var(--white);
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px; */
}

.about_container {
    background: url('../images/aboutBg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 30%;

}

.about_bg_shadov {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.about_content {
    /* background-color: rgba(128,0,128,0.5); */
    width: 55%;
    padding: 100px 60px;
    /* margin-left: auto; */
}

.about_content {
    /* position: relative;
    width: 300px;
    height: 300px;
    color: #fff;
    background: transparent;
    overflow: hidden;
    border-top: 1px solid rgba(255, 49, 49, 0.5);
    border-right: 1px solid rgba(0, 255, 255, 0.5);
    border-bottom: 1px solid rgba(57, 255, 20, 0.5);
    border-left: 1px solid rgba(255, 255, 113, 0.5);
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1em; */
}

/* .top span,.right span,.left span,.bottom span {
    position: absolute;
    border-radius: 100vmax;
  } */

.top {
    top: 0;
    left: 0;
    width: 0;
    height: 5px;
    background: linear-gradient(90deg,
            transparent 50%,
            rgba(34, 4, 255, 0.5),
            rgb(255, 49, 49));
}

.bottom {
    right: 0;
    bottom: 0;
    height: 5px;
    background: linear-gradient(90deg,
            rgb(57, 255, 20),
            rgba(57, 255, 20, 0.5),
            transparent 50%);
}

.right {
    top: 0;
    right: 0;
    width: 5px;
    height: 0;
    background: linear-gradient(180deg,
            transparent 30%,
            rgba(0, 255, 255, 0.5),
            rgb(0, 255, 255));
}

.left {
    left: 0;
    bottom: 0;
    width: 5px;
    height: 0;
    background: linear-gradient(180deg,
            rgb(255, 255, 113),
            rgba(255, 255, 113, 0.5),
            transparent 70%);
}

.top {
    animation: animateTop 3s ease-in-out infinite;
}

.bottom {
    animation: animateBottom 3s ease-in-out infinite;
}

.right {
    animation: animateRight 3s ease-in-out infinite;
}

.left {
    animation: animateLeft 3s ease-in-out infinite;
}

@keyframes animateTop {
    25% {
        width: 100%;
        opacity: 1;
    }

    30%,
    100% {
        opacity: 0;
    }
}

@keyframes animateBottom {

    0%,
    50% {
        opacity: 0;
        width: 0;
    }

    75% {
        opacity: 1;
        width: 100%;
    }

    76%,
    100% {
        opacity: 0;
    }
}

@keyframes animateRight {

    0%,
    25% {
        opacity: 0;
        height: 0;
    }

    50% {
        opacity: 1;
        height: 100%;
    }

    55%,
    100% {
        height: 100%;
        opacity: 0;
    }
}

@keyframes animateLeft {

    0%,
    75% {
        opacity: 0;
        bottom: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        height: 100%;
    }
}

.about_content_card {
    background-color: #000000a3;
    padding: 50px;
    /* margin: 10px; */
    border-radius: 8px;
    min-width: 600px;
    position: relative;
    /* width: 300px; */
    /* height: 300px; */
    /* background: transparent; */
    overflow: hidden;
    border-top: 1px solid rgba(136, 0, 255, 0.908);
    border-right: 1px solid rgba(76, 0, 255, 0.5);
    border-bottom: 1px solid rgba(145, 20, 255, 0.5);
    border-left: 1px solid rgba(177, 113, 255, 0.5);
    /* font-family: sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; */
    /* padding: 1em; */

}

.about_content h3 {
    color: var(--white);
    margin-bottom: 40px;
    font-size: 2.5rem;
}


.about_content p {
    color: var(--white);
    font-weight: 400;
    font-family: 'Mulish', sans-serif;
    line-height: 30px;



}

.partners_section {
    background: url('../images//tip4.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.partners_section .partners_section_bg_shadow {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    padding-bottom: 200px;
    padding-top: 60px;
}

.partners_section .section_header {
    color: var(--white);
}

.partners_section .section_header_link {
    color: var(--white);
}

.footer_header_bg {
    background-color: var(--purple);
    padding: 50px 0;

}

.footer_header_container {
    display: flex;
    align-items: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.footer_header h2 {
    color: var(--white);
    font-size: 3rem;
}

.footer_header p {
    color: var(--white);
    margin-top: 15px;
    font-size: 1.8rem;
}

.footer_read_more button {
    margin-top: 0;
}

.footer_read_more button:before {
    background-color: var(--yellow);

}

.footer_second {
    background: url('../images/footer-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.footer_second_btn button {
    padding: 4px 14px;
    margin: 0;
    background-color: var(--purple);
    color: var(--white);
    font-size: 1.6rem;
    margin-top: 20px;
}

.footer_second_btn button::before {
    background-color: var(--black3);
}

.footer_second_card_map iframe {
    border: none;
    width: 100%;
    height: 140px;
}

.footer_second_container {
    display: flex;
    gap: 20px;
    /* justify-content: space-between; */
}

.footer_second_cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;

}

.footer_second_card {
    max-width: 270px;

}

.footer_second {
    padding-top: 80px;
    padding-bottom: 80px;
}

.footer_second_card h4 {
    margin-bottom: 30px;
    font-size: 2rem;
}

.footer_second_card p {
    color: var(--black3);
    font-size: 1.3rem;
    line-height: 25px;
}

.footer_second_card_content {
    margin-bottom: 14px;
    display: flex;
    gap: 5px;
}

.footer_second_card_content a {
    color: var(--black3);
    font-size: 1.4rem;
    transition: all 0.5s ease-in-out;
    font-weight: 400;
}

.footer_second_card_content a:hover {
    color: var(--purple);
}

.footer_second_card_content .footer_second_card_link {
    font-size: 1.8rem;
    background-color: var(--white);
    border: 0.1px solid var(--black2);
    /* box-shadow: 0 0 9px rgba(69, 69, 69, 0.23); */
    padding: 2px;
}

.footer_end_bg {
    background-color: var(--purple);
    padding: 60px 0;

}

.footer_end_link {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    gap: 10px;
}

.footer_end_link li a {
    color: var(--white);
    font-size: 1.6rem;
    transition: all 0.2s ease-in-out;
}

.footer_end_link li a:hover {
    opacity: 0.4;
}

.footer_end_social {
    display: flex;
    gap: 20px;
}

.footer_end_social li a {
    color: var(--white);
    transition: all 0.5s ease-in-out;
}

.footer_end_social li a:hover {
    opacity: 0.7;
}

.footer_end_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: relative; */
}

.footer_logo {
    width: 150px;
}



.teachers_img_pagination {
    /* display: flex; */
    /* background-color: red; */
    max-width: 510px;
    /* justify-content: space-between;
    align-items: center;
    gap: 20px; */
}

.teachers_slider_paginations_card {
    width: 150px !important;
    height: 150px !important;
    /* height: 160px; */
    border-radius: 50%;
    object-fit: cover;
    /* background-color: red; */

}

.teachers_slider_paginations_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.active_slide {
    /* border: 1px solid red; */
    /* width: 250px !important; */
    /* background-color: lime; */
    border: 1px dotted var(--black2);
    padding: 6px;
}

/* .active_slide */
.teachers_slider_card_container {
    /* background-color: red; */
    /* text-align: center;
    margin-top: 50px;
    margin-bottom: 50px; */
}

.teachers_slider_card_card {
    box-shadow: 0px 0px 0px 0px rgba(4, 26, 47, 0.06),
        0px 3px 6px 0px rgba(4, 26, 47, 0.06), 0px 12px 12px 0px rgba(4, 26, 47, 0.05),
        0px 26px 16px 0px rgba(4, 26, 47, 0.03),
        0px 47px 19px 0px rgba(4, 26, 47, 0.01), 0px 74px 21px 0px rgba(4, 26, 47, 0);
    transform-style: preserve-3d;
}














.teachers_container {
    /* background-color: #E3F2FD; */
    position: relative;
}

.teachers_slider_card_container {
    position: relative;
    max-width: 1170px;
    width: 100%;
    padding-bottom: 50px;
    overflow: hidden;
    /* background-color: lime; */


}

.teachers_slider_card_container .image {
    height: 170px;
    width: 170px;
    object-fit: cover;
    border-radius: 50%;
}

.teachers_slider_card_container .teachers_slider_card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 30px;
    /* height: 100%; */
    /* width: 100%; */
    /* min-width: 277px; */
    /* background-color: red; */
}

.teachers_slider_card img {
    /* background-color: red; */
    border-radius: 10px;
    width: 100%;
}

.teachers_slider_card p {
    text-align: center;
    /* padding: 0 160px; */
    font-size: 14px;
    font-weight: 400;
    color: #333;
}

.teachers_slider_card .quote-icon {
    font-size: 30px;
    color: #4070f4;
}

.teachers_slider_card .details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.details .name {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.details .job {
    font-size: 12px;
    font-weight: 400;
    color: #333;
}

/* swiper button css */
.teachers_next_btn,
.teachers_prev_btn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transform: translateY(30px);
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 9999 !important; */
    cursor: pointer;

}

.teachers_next_btn:hover,
.teachers_prev_btn:hover {
    background-color: rgba(0, 0, 0, 0.2);
    /* border-color: var(--purple); */
}

.teachers_next_btn::after,
.teachers_prev_btn::after,
.teachers_next_btn::before,
.teachers_prev_btn::before {
    font-size: 20px;
    color: #fff;
}

/* .swiper-pagination-bullet {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .swiper-pagination-bullet-active {
    background-color: #4070f4;
  } */

.teachers_next_prev_btns {
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    max-width: 1300px !important;
    width: 100%;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 999;
}

.teachers_section {
    background-color: var(--white2);
    /* margin-top: 140px; */
    /* padding-bottom: 50px; */
}





















.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;
}

.cards .card {
    height: 380px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 20px 30px -10px rgba(0, 0, 0, 0.1);
    /* max-width: 250px; */
    min-width: 277px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    transition: all 0.4s ease;
    margin: 0 10px;
}

.cards .card:before {
    height: 190px;
    width: calc(100% + 100px);
    content: "";
    position: absolute;
    background-image: linear-gradient(to top, #8A5DA4 0%, #a408ff 100%);
    border-radius: 4px 4px 100% 100%;
    transition: all 0.4s ease-out;
    top: 0;
}

.cards .card article {
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.cards .card article h2 {
    color: white;
    margin: 0;
    padding: 40px 20px 10px 20px;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.5px;
}

.cards .card article .title {
    color: white;
    padding: 10px 20px;
    letter-spacing: 0.8px;
    transition: all 0.4s ease;
}

.cards .card article .desc {
    padding: 10px 30px;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    color: #666;
    height: 90px;
    transition: all 0.4s ease-out;
}

.cards .card article .pic {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 100%;
    margin: 20px 0;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.3);
    transition: all 0.6s ease;
}
.publication_container{
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;
}
.publication_card{
    min-width: 277px;
    transition: all 0.4s ease;
    box-shadow: 0px 0px 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
    padding-top: 5px;
    
}
.publication_card h2{
    margin-bottom: 5px;
    text-align: center;
}
.publication_card:hover{
    transform: translateY(-10px);
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
}
.publication_card img{
    width: 100%;
    border-radius: 0 0 6px 6px;
}

.cards .card article .pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* -webkit-filter: grayscale(100%); */
    /* filter: grayscale(100%); */
}

.cards .card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.3);
}

.cards .card:hover:before {
    height: 100%;
    border-radius: 4px;
}

.cards .card:hover .desc {
    color: white;
}

.cards .card:hover .pic {
    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.3);
}

.cards .card:hover .pic img {
    /* -webkit-filter: grayscale(0%);
    filter: grayscale(0%); */
}


.bg_new_letter {

    background: url('../images/bg-newletter.webp') no-repeat top right;
    max-width: 753px;
    margin: auto;
    text-align: center;
    padding: 0 15px;
    padding-top: 75px;
    padding-bottom: 70px;
    margin-top: 60px;
    /* margin-bottom: 60px; */
}

.bg_new_letter p {
    max-width: 490px;
    margin: 20px auto 40px;
    line-height: 30px;
    font-size: 1.8rem;
}

.bg_new_letter input {
    padding: 0 5px;
    height: 50px;
    outline: none;
    border: 0.1px solid var(--black2);
    width: 60%;
    font-size: 1.6rem;
}

.bg_new_letter button {
    border: none;
    padding: 0 10px;
    display: inline-block;
    background-color: var(--purple);
    color: var(--white);
    height: 50px;
    font-size: 1.6rem;
}

.fixed_social {
    /* background-color: red; */
    position: fixed;
    top: 40%;
    right: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.fixed_social div {
    background-image: conic-gradient(var(--white) 25%, var(--white) 0 45%, #dadee2 0 45%, #e8ebed 0 55%, #f6f7f8 0 65%, #fff 0 75%, var(--white2) 25%);
    ;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 0 0 6px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);
}

.fixed_social div:nth-child(1) .fixed_social_icon {
    color: var(--facebook);
    font-size: 2.5rem;
}

.fixed_social div:nth-child(2) .fixed_social_icon {
    color: var(--intagram);
    font-size: 2.5rem;
}

.fixed_social div:nth-child(3) .fixed_social_icon {
    color: var(--youtube);
    font-size: 2.5rem;
}

.fixed_social div:nth-child(4) .fixed_social_icon {
    color: var(--whatsapp);
    font-size: 2.5rem;
}





/* *********************************** */

/* fallingTextAnimation.css */







/* ********************************** */





.haeder_one {

    position: relative;
    /* transform: translate(-50%,-50%); */

}

.haeder_one.headActive {}

/* .haeder_one span {
    
    
    display: inline-block;
    
    animation: fall 3.5s forwards;
    
   
}

@keyframes fall{
    100%{
        transform: translateY(0);
        transform: translateX(0);
    }
}
.haeder_one span:nth-child(2){
    animation-delay: 0.1s;
}
.haeder_one span:nth-child(3){
    animation-delay: 0.2s;
}
.haeder_one span:nth-child(4){
    animation-delay: 0.3s;
}
.haeder_one span:nth-child(5){
    animation-delay: 0.3s;
}
.haeder_one span:nth-child(6){
    animation-delay: 0.4s;
}
.haeder_one span:nth-child(7){
    animation-delay: 0.4s;
} */
.haeder_one .activeSpan {}


.other_header_main_container {
    height: 40vh;



}


.other_bg_shadow {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;

}

.other_banner_content {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    
}

.other_header_main_container h1 {
    color: var(--white);
    position: relative;
    padding-left: 20px;
    margin-bottom: 100px !important;
    /* background-color: lime; */
    font-family: 'Merriweather Sans', sans-serif;
}

.other_header_main_container h1::before {
    position: absolute;
    content: '';
    left: 0;
    background-color: var(--purple);
    width: 4px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
}

.other_header_pages_link {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 0.8px solid var(--gray);
}

.other_header_pages_link a {
    font-size: 1.4rem;
    font-style: italic;
    color: var(--purple);
    font-weight: 500;
}

.other_header_pages_link p {
    font-size: 1.4rem;
    font-style: italic;
    color: var(--black);
    font-weight: 400;
}

.other_about_container {
    /* background-color: red; */
    display: flex;
    gap: 100px;
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 120px;
}

.other_about_content {
    max-width: 700px;
}

.other_luck_parent {
    margin-top: 60px;
    margin-bottom: 120px;
}

.other_about_content p {
    margin-top: 20px;
    color: var(--black3);
    font-size: 1.5rem;
    line-height: 20px;
}

.latest_news_cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

.latest_news_card {
    display: flex;
    gap: 15px;
}

.latest_news_card img {
    width: 100px;
}

.latest_news_container .section_header {
    font-size: 3rem;
}

.latest_news_card h3 {
    font-size: 1.3rem;
    font-family: 'Merriweather Sans', sans-serif;
    line-height: 20px;
}

.other_about_content h5 {
    font-size: 1.5rem;
    line-height: 20px;
    font-family: 'Mulish', sans-serif;
}

.other_service_content {
    max-width: 700px;
}

.other_service_content .services_container {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;
}

.other_service_content .services_card {
    min-width: 277px;

}

.other_news_cards_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;
    margin-top: 60px;
    margin-bottom: 120px;

}

.other_news_container,
.other_foto_container,
.other_video_container,
.other_cv_send_container,
.news_retrieve_cards,
.detail_news_container,
.other_publication_container {
    margin-top: 60px;
    margin-bottom: 120px;
}

.other_video_cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
}

.other_video_card {
    min-width: 300px;
}

.other_video_card iframe {
    width: 100%;
    height: 300px;
}

.other_contact_content .footer_second_card {
    border-top: 0.8px solid var(--gray);
    border-bottom: 0.8px solid var(--gray);
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.other_contact_form_card input {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid var(--gray);
    padding: 14px;
    outline: none;
    font-family: 'Mulish', sans-serif;
    display: block;
    color: var(--black4);
}

.other_contact_form_card {
    margin-bottom: 20px;


}

.other_contact_form {
    /* background-color: red; */
    width: 100%;
    margin-top: 30px;
}

.other_contact_form_card textarea {
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid var(--gray);
    padding: 14px;
    outline: none;
    resize: none;
    color: var(--black4);
}

.other_contact_form_card input::placeholder,
.other_contact_form_card textarea::placeholder {
    color: var(--black3);
}


.other_contact_form button,
.other_cv_send_form_container button {
    /* margin-top: 50px; */
    font-size: 1.6rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
    color: var(--black);
    border: none;
    padding: 6px 25px;
    border-radius: 12px;
    transition: 0.3s ease-in-out;
    transition-delay: 0.35s;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    background-color: var(--white);
}

.other_contact_form button:before,
.other_cv_send_form_container button::before {
    transform: translate(-110%, -110%) translateZ(0);
    content: "";
    display: block;
    background: var(--purple);
    position: absolute;
    width: 200%;
    height: 500%;
    border-radius: 100%px;
    transition: 0.36s cubic-bezier(0.4, 0, 1, 1);
    color: var(--white);
}

.other_contact_form button:hover:before,
.other_cv_send_form_container button:hover:before {
    transform: translate(-45%, -34%) translateZ(0);
}

.other_contact_form button:hover span,
.other_cv_send_form_container button:hover span {
    color: var(--white);
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
    transition: 0.25s ease-in;
    transition-delay: 0.1s;
}

.other_contact_cards {
    display: flex;
    /* background-color: lime; */
    gap: 100px;
    /* align-items: center; */

}

.other_contact_content {
    width: 50%;
    background-color: var(--white);
    padding: 40px;
}

.contact_section {
    background: url('../images/Contact-us-background.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.other_contact_container {
    padding-bottom: 150px;
    margin-top: 60px;
}

.other_contact_maps {
    /* margin-top: 100px; */
}

.other_contact_maps iframe {
    border: none;
    width: 100%;
}

.other_contact_content p {
    font-size: 1.4rem;
    line-height: 20px;
}


.other_cv_send_form_input_card input,
.other_cv_send_form_input_container textarea {
    width: 100%;
    background: none;
    border: 1px solid var(--gray);
    padding: 14px;
    outline: none;
    font-family: 'Mulish', sans-serif;
    display: block;
    color: var(--black4);
}

.other_cv_send_form_input_card {
    margin-bottom: 20px;
    width: 100%;
}
.course_online_reg_select select{
    background-color: var(--yellow);
    color: var(--white);
    border: none;
    outline: none;
    padding: 14px;
    font-size: 2rem;
    border-radius: 6px;
    appearance: none;
    width: 100% !important;
    
}
.course_online_reg_select{
    /* width: 50%; */
    position: relative;
    display: flex;
    align-items: center;
    
}
.course_online_reg_select .select_down_icon{
    position: absolute;
    right: 15px;


    pointer-events: none;
    width: 20px;
    height: 20px;
    color: var(--white);
}
.other_cv_send_form_input_container {
    display: flex;
    gap: 35px;
}

.other_cv_send_form_card h3 {
    margin-bottom: 15px;
    margin-top: 40px;
    color: var(--black4);
    font-weight: 400;
    font-size: 2.5rem;
}

.other_cv_send_form_input_diff_container .other_cv_send_form_input_container:not(:last-child) {
    border-bottom: 1px solid var(--gray);

}

.other_cv_send_form_input_diff_container .other_cv_send_form_input_container:not(:first-child) {
    margin-top: 20px;
}

.other_cv_send_form_input_container textarea {
    width: 100%;
    resize: none;
}

.other_cv_send_container h4 {
    color: var(--black4);
}

.other_cv_send_form_card p {
    color: var(--black3);
    font-size: 1.5rem;
    line-height: 20px;
}

.detail_news_card div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.detail_news_card div span {
    font-style: italic;
    color: var(--black3);
}

.detail_news_container {
    /* background-color: lime; */
    max-width: 700px;

}

.detail_news_card img {
    width: 100%;
}

.detail_news_card p {
    margin-top: 6px;
}

.other_foto_card img {
    width: 100%;
}

.other_foto_cards {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;

}
.other_foto_card{
    min-width: 277px;
    box-shadow: 0px 0px 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
}
.other_foto_card_content {
    width: 100%;
    padding: 20px;
}

.contact_create_modal {
    max-width: 600px;
}

.swal2-popup {
    font-size: 1.6rem;

    font-family: 'Mulish', sans-serif;
}

.swal2-title {
    font-weight: 600;
}



/* loading */

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid var(--purple);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.header_loading {
    background-color: rgba(246, 246, 246, 0.6);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999999999999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
}

.detail_teacher_card {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.detail_teacher_card img {
    /* height: 300px; */
    width: 100%;
    border-radius: 0;
}

.detail_teacher_card h3 {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.detail_teacher_card p {
    font-size: 1.6rem;
    margin-bottom: 8px;
}

.detail_teacher_card span {
    font-style: italic;
    color: var(--purple);
}

.detail_teacher_card h5 {
    text-align: center;
    font-family: 'Mulish', sans-serif;
    font-size: 1.6rem;
    margin-bottom: 6px;
    font-weight: 400;
    color: var(--yellow);
}

.other_luck_container {
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr)) !important;
    gap: 20px;
}

.other_luck_card {
    min-width: 340px;
    box-shadow: 0px 0px 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
}

.other_luck_card img {
    width: 100%;
    height: 300px;
    border-radius: 6px 6px 0 0;
    transition: all 0.5s ease-in-out;
}

.other_luck_card h3 {
    margin-top: 8px;
    margin-bottom: 10px;
    text-align: center;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
}

.other_luck_card p {
    color: var(--black2);
    margin-bottom: 60px;
    text-align: center;
}

.other_luck_card:hover img {
    opacity: 0.5;
}






.login_container {
    background-color: var(--gray);
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login_container img{
    margin-bottom: 100px;
}
.login {
    max-width: 325px;
    /* background-color: red; */
}



.login label {
    border-radius: 5px 5px 0 0;
    padding: 5px 12px;
    color: var(--white);
    font-size: 2rem;
    font-weight: 300;
    background: var(--purple);




}

.login input {
    display: block;
    width: 100%;
    height: 47px;
    margin-top: 4px;
    border: 0.75px solid var(--gray2);
    border-radius: 0 4px 4px 4px;
    outline: none;
    font-size: 2rem;
    padding-left: 5px;
}

.password {
    /* margin-top: 28px; */
    width: 70%;
}

.email {
    margin-top: 72px;
}



.login_btn {
    padding: 9px 15px;
    color: var(--orange);
    font-size: 1.8rem;
    font-weight: 500;
    border-radius: 6px;
    border: none;
    margin-top: 61px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    cursor: pointer;
}
.register_btn{
    margin-top: 30px;
}

.login_psw p,
.login_email p,
.reg_err_psw {
    color: red;
    font-size: 1.4rem;
    margin-top: 10px;
}

/* Register */

.register_line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
    flex-direction: column;
    /* background-color: red; */
}
.register_name{
    margin-top: 28px;

}
.register_email,
.register_name,
.register_surname {
    width: 70%;
}
.register_check_container{
    display: flex;
    align-items: center;
    /* gap: 20px; */
    margin-top: 30px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}
.register_check{
    display: flex;
    align-items: center;
    gap: 10px;
    
}
.register_check input{
    width: 16px;
    margin: 0;
}
.register_check label{
    background: none;
    color: var(--black);
    padding: 0;
    font-size: 1.8rem;
}
#register {
    max-width: 555px;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

#register img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 0;
}
#login{
    max-width: 555px;
    width: 100%;
}
#login img{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.dont_have_account {
    margin-top: 10px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.dont_have_account a {
    color: var(--intagram);
}




.accounting_page {
    height: 70vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.accounting_create{
    display: flex;
    justify-content: flex-end;
    height: 10vh;
    align-items: center;
    gap: 20px;
}
.branchs_create{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
}
.accounting_create_btn{
    padding: 9px 15px;
    color: var(--white);
    font-size: 1.8rem;
    font-weight: 500;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    background-color: var(--purple);
    transition: 0.5s ease-in-out;
}

.accounting_create_btn:hover{
    background-color: var(--gray);
    color: var(--black);
}
.branchs_container {
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
}

.branchs_card {
    min-width: 250px;
    background-color: var(--white);
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    padding: 60px 0;
    text-align: center;
    border-radius: 6px;
}

.branchs_card h2 {
    color: var(--black4);
    font-weight: 500;
}

.branchs_seasons {

    /* margin: 30px auto; */
    width: 200px;

    position: relative;
    display: flex;
    align-items: center;

}

.branchs_seasons select {
    background: var(--purple);
    color: var(--white);
    border: none;
    outline: none;
    padding: 6px 10px;
    font-size: 2rem;
    border-radius: 6px;
    appearance: none;

    width: 100%;

}

.select_down_icon {
    position: absolute;
    right: 15px;


    pointer-events: none;
    width: 20px;
    height: 20px;
    color: var(--white);

}

.branchs_services {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;
    /* min-height: 40vh; */
    align-items: center;
    
}

.branchs_services_card {
    min-width: 277px;
    background-color: var(--white);
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
    padding: 20px 0;
    text-align: center;
    cursor: pointer;
}

.branchs_services_card h2 {
    color: var(--black4);
    font-weight: 400;
}

.branchs_students_table_header {
    display: flex;
    justify-content: space-between;
}

.branchs_students_container {
    border: 2px solid var(--black);
    margin-top: 40px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.branchs_students_table_inner_header:not(:last-child),
.branchs_students_inner_card:not(:last-child) {
    /* border-right: 2px solid var(--black); */
    /* background-color: lime; */

}

.branchs_students_table_inner_header {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-weight: 500;
    font-size: 1.5rem;
    /* width: 200px; */
}

.branchs_students_card {
    /* display: flex;
    justify-content: space-between;
    border-top: 2px solid var(--black); */
}

.branchs_students_inner_card {
    /* width: 100%; */
    text-align: center;
    padding: 10px 0;
    font-size: 1.4rem;

}

.branchs_abiturients_cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));
    gap: 20px;
    margin-top: 40px;
    align-items: center;
}

.branchs_abiturients_card {
    min-width: 277px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
    padding: 30px 0px;
}

.branchs_abiturients_cards_container h2 {
    margin-top: 30px;
    text-align: center;
    color: var(--intagram);
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
}

.branchs_abiturients_cards_container:not(:last-child) {
    border-bottom: 2px solid var(--gray2);
    padding-bottom: 20px;
}

.branchs_abiturients_block_card:last-child {
    border-bottom: none;
}

.branchs_abiturients_block_card:first-child {
    border-top: none;
}

.branchs_abiturients_container {
    max-width: 400px;
}

.all_abiturient {
    background-color: var(--intagram);
    max-width: 300px;
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
    padding: 30px 0px;
    margin-top: 40px;
    margin-bottom: 40px;
    cursor: pointer;
}

.all_abiturient h2 {
    margin: 0;
    color: var(--white);
    font-weight: 500;
    font-size: 2rem;
}

.accounting_abiturient_total_container {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}
.accounting_abiturient_total_container th{
    font-size: 1.4rem;
    min-width: 200px;
    font-weight: 800;
    
}
.accounting_students_status_color_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 100px;
}

.accounting_students_status_color_container .accounting_students_status_color_card {
    display: flex;
    gap: 10px;
    align-items: center;
}

.accounting_students_status_color_container .accounting_students_status_color_card div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.accounting_students_status_color_container .accounting_students_status_color_card:nth-child(1) div {
    background-color: green;
}
.accounting_students_status_color_container .accounting_students_status_color_card:nth-child(2) div {
    background-color: var(--facebook);
}
.accounting_students_status_color_container .accounting_students_status_color_card:nth-child(3) div {
    background-color: red;
}

.accounting_students_status_color_container .accounting_students_status_color_card:nth-child(4) div {
    background-color: #EAAE0D;
}

.accounting_students_status_color_container .accounting_students_status_color_card:nth-child(5) div {
    background-color: purple;
}


.accounting_students_status_color_container .accounting_students_status_color_card:nth-child(6) div {
    background-color: #edebeb;
}
.accounting_students_status_color_container .accounting_students_status_color_card:nth-child(7) div {
    background-color: #00f2ff;
}


table,
td,
th {
    border: 1px solid #ddd;
    text-align: center;
}

table {
    border-collapse: collapse;
    /* width: 100%; */
    overflow-x: auto !important;
    font-family: 'Roboto', sans-serif;
    /* max-width: 1000px !important; */
}
th{
    font-size: 1.4rem;
    font-weight: 600;
    min-width: 110px;
}
th,
td {
    padding: 10px 0;
    width: 120px;
    min-width: 100px;
}
td{
    font-size: 1.4rem;
    font-weight: 400;
}
table{
   max-width: 1300px !important;
   margin-left: auto;
   margin-right: auto;
   
}
.handle_student_table{
    margin-top: 30px;
}

.notification_container{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
}
.notification_card{
    background-color: var(--white);
    border-radius: 6px;
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.notification_card p:nth-child(2) span,
.notification_card p:nth-child(3) span{
    color: var(--intagram);
}
.notification_card p:nth-child(4) span,
.notification_card p:nth-child(5) span{
    color: var(--yellow);
}


.modal_container{
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
.branch_create_modal_card{
    background-color: var(--white);
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
    min-height: 100px;
    max-height: 60vh;
    min-width: 300px;
    max-width: 800px;
    z-index: 99;
    padding: 10px 20px;
    overflow: auto;
}
.branch_create_modal_card select{
    display: block;
    margin-bottom: 10px;
}
.branch_create_modal_card input{
    margin-bottom: 10px;
}
.multi_select{
    margin-bottom: 10px;
    width: 60%;
    color: black;
   
    
}

.overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

}
.modal_close_btn{
    background-color: var(--youtube) !important;
    border: none;
    padding: 4px 12px !important;
    border-radius: 6px;
    font-size: 1.6rem;
    color: var(--white);
    margin-left: auto;
    display: block;
    margin-bottom: 20px;
    
}
.branch_create_modal_card input{
    background: none;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--black2);
    padding: 6px 4px;
    font-size: 1.8rem;
    width: 100%;
}
.modal_save_btn_container button{
    background-color: var(--facebook);
    border: none;
    padding: 4px 12px;
    border-radius: 6px;
    font-size: 1.6rem;
    color: var(--white);
    margin-top: 20px;
}
.season_select_container{
    display: flex;
    align-items: center;
    /* background-color: red; */
    justify-content: center;
    padding: 30px 0;
    gap: 30px;
}
.branchs_seasons{
    /* background-color: lime; */
}
.season_select_container button{
    background: var(--purple);
    color: var(--white);
    border: none;
    outline: none;
    padding: 6px 10px;
    font-size: 2rem;
    border-radius: 6px;

}
.notification_btn_container{
    display: flex;
    justify-content: flex-end;
}
.notification_btn_container button{
    background: var(--purple);
    color: var(--white);
    border: none;
    outline: none;
    padding: 4px 8px;
    font-size: 1.6rem;
    border-radius: 6px;
}
.branchs_category_student_create .season_select_container{
    padding: 0;
}
.branchs_category_student_create{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.branch_update_modal_card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    
}
.branch_update_modal_card:not(:last-child){
    border-bottom: 1px solid var(--gray);
   
}

.branch_update_modal_container{
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    /* background-color: lime; */
    min-width: 400px;
}
.branch_update_modal_btn{
    padding: 4px 12px !important;
    color: var(--white);
    font-size: 1.6rem !important;
    font-weight: 500;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    background-color: var(--purple);
}
.season_update_delete_btns{
    display: flex;
    gap: 20px;
    align-items: center;
   
}

.branch_create_modal_card select{
    background: var(--gray);
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    border-radius: 6px;
    outline: none;
    border: none;
    padding: 4px 8px;
    margin-top: 20px;
    font-size: 1.8rem;
}

.information_container{
    margin-top: 60px;
    color: var(--facebook);
    font-size: 2rem;
    font-weight: 500;
}

.student_payment_status{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
   
    
}
.student_payment_status input{
    width: 30px;
    margin: 0;
    padding: 0;
}


.isAccountant input{
    width: auto;
    margin: 0;
}

.edit_student_payment{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--black2);
}
.edit_student_payment input{
    width: 40%;
    border-bottom: none;
    font-size: 1.6rem;
    margin: 0;
}
.edit_student_payment label{
    font-size: 1.6rem;
}
.edit_branch_create_modal_card{
    max-width: 400px;
    width: 100%;
}
table .table_tr_style td{
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
}
.manat_icon{
    font-size: 1.2rem;
}
.course_online_register_select{
    margin-bottom: 20px;
}
#angleOne,
#angleTwo{
    display: none;
}

.other_exam_results_container{
    height: 200px;
    max-width: 400px;
    background-color: var(--white);
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 0.5px solid var(--purple);
}

.other_exam_results_container::before{
   position: absolute;
    content: '';
    height: 220px;
    width: 400px;
    background: -webkit-linear-gradient(top, rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
    box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
    transform: rotate(-3deg);
    z-index: -999999;
    /* background-color: red; */
    top: 0px;
    border: 0.5px solid var(--purple);
    

}

.other_exam_results_container::after{
    position: absolute;
     content: '';
     height: 220px;
     width: 400px;
     background: -webkit-linear-gradient(top, rgba(248,248,248,1) 0%,rgba(249,249,249,1) 100%);
     box-shadow: 0 0 9px rgba(69, 69, 69, 0.23);
     transform: rotate(2deg);
     z-index: -1;
     /* background-color: red; */
     top: 0px;
     border: 0.5px solid var(--purple);
     
 
 }

 .exam_results_inp_icon{
    display: flex;
    align-items: center;
    gap: 5px;
    width: 300px;
    border: 0.2px solid var(--purple);
    padding: 10px;
 }
 .exam_results_inp_icon input{
    
    
    width: 90%;
    border: none;
    outline: none;
    background: none;
 }

 input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.other_exam_results_container p{
    font-size: 1.2rem;
    margin-top: 10px;
    color: var(--youtube);
}

